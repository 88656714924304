<div
  *ngIf="dataIsLoaded"
  #wrapperElementTemplate
  class="goal-card-container"
  appTooltip
  [appTooltipIsShown]="
    (currentActiveStepSelector$ | async) === (isOnboardingV2Enabled ? 3 : 4) &&
    wrapperElementTemplateRef !== undefined
  "
  [appTooltipCaretConfig]="{
    hasCaret: true,
    height: 37,
    width: 37,
    positionOnOrigin: 'right',
    placementY: '40px',
    color: 'white',
    offsetFromHostElement: 21
  }"
  [appTooltipInjectionPayload]="{
    componentToInject: tooltipComponentRef,
    data: {
      currentStep: isOnboardingV2Enabled ? 3 : 4,
      uiContent: {
        title: {
          classes: 'heading2--bold',
          styling: {
            'column-gap': '8px'
          },
          sections: [
            {
              text: 'ONBOARDING.STEPS.4.HEADER.a',
              styling: {
                color: '#0F1933'
              }
            }
          ]
        },
        body: {
          paragraphs: [
            {
              classes: 'body2--regular',
              styling: {
                'text-align': 'initial'
              },
              sections: [
                {
                  text: 'ONBOARDING.STEPS.4.BODY.a.' + (isMoneyBack ? 'cashback' : 'badge'),
                  styling: {
                    'font-weight': '400',
                    color: '#0F1933'
                  }
                }
              ]
            }
          ]
        },
        footer: {
          classes: 'body2--medium',
          styling: {
            'justify-content': 'flex-end'
          },
          buttons: [
            {
              action: 'next',
              styling: {
                padding: '16px 48px',
                'column-gap': '8px'
              },
              sections: [
                {
                  type: 'text',
                  content: 'ONBOARDING.STEPS.4.FOOTER.a'
                },
                {
                  type: 'image',
                  content: goalAssetsPath + 'next-step-left-arrow.svg'
                }
              ]
            }
          ]
        }
      }
    }
  }"
  [appTooltipConnectedElement]="wrapperElementTemplateRef"
  [appTooltipAttachedToElement]="wrapperElementTemplate"
  [appTooltipStyling]="{
    'background-color': 'white',
    'border-radius': '10px'
  }"
  [appTooltipOverlayPosition]="{
    positionOnOrigin: 'left',
    placementY: '170px'
  }"
  [appTooltipDimensions]="{
    width: '502px'
  }"
  [appTooltipHasBackdrop]="true"
  [appTooltipParentCustomStyling]="{
    padding: 0
  }"
>
  <div class="goal-card-container__header section-container">
    <img *ngIf="isMoneyBack" src="{{ goalAssetsPath + 'treasure.svg' }}" />
    <img *ngIf="isBadge" src="{{ goalAssetsPath + 'badge.svg' }}" />
    <div>
      <p *ngIf="isMoneyBack" class="text-on-primary heading2--extra-bold">
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.BANNER.TEXT.CASHBACK.a' | translate }}
        <span class="text-secondary-yellow">{{
          'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.BANNER.TEXT.CASHBACK.b'
            | translate : { currentCurrency, amount }
        }}</span>
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.BANNER.TEXT.CASHBACK.c' | translate }}
      </p>
      <p *ngIf="isBadge" class="text-on-primary heading2--extra-bold">
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.BANNER.TEXT.BADGE.a' | translate }}
        <span class="text-secondary-yellow">
          {{
            'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.BANNER.TEXT.BADGE.b' | translate : { amount }
          }}</span
        >
      </p>
      <p class="text-on-primary caption2--regular">
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.BANNER.SUBTEXT' | translate }}
      </p>
    </div>
  </div>
  <div class="goal-card-container__progress section-container">
    <p class="body2--medium text-content">
      {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.PROGRESS.TEXT' | translate }}
    </p>
    <div class="goal-card-container__progress__goal">
      <app-shared-progress-bar
        class="goal-card-container__progress__goal__bar"
        [progressScore]="progressPercentage"
      ></app-shared-progress-bar>
      <p class="body2--medium text-black">
        {{ achievedGoals }}
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.PROGRESS.INDICATOR_TEXT.a' | translate }}
        {{ allGoals }}
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.PROGRESS.INDICATOR_TEXT.b' | translate }}
      </p>
    </div>
  </div>
  <div class="goal-card-container__goals section-container">
    <div class="goal-card-container__goals__header">
      <img src="{{ goalAssetsPath + 'flag.svg' }}" />
      <p class="body1--bold text-content">
        {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.GOALS.TITLE' | translate }}
      </p>
    </div>
    <div
      *ngFor="let goal of [].constructor(allGoals); let index = index"
      class="goal-card-container__goals__goal"
    >
      <div>
        <img *ngIf="index >= achievedGoals" src="{{ goalAssetsPath + 'un-check.svg' }}" />
        <img *ngIf="index < achievedGoals" src="{{ goalAssetsPath + 'check.svg' }}" />
      </div>
      <div class="goal-card-container__goals__goal__description">
        <p
          [ngClass]="index < achievedGoals ? 'text-black' : 'text-content-medium'"
          class="body2--medium"
        >
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.GOALS.DESCRIPTION.a' | translate }}
          {{ index + 1 }}
          {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.GOALS.DESCRIPTION.b' | translate }}
        </p>
      </div>
    </div>
    <div class="goal-card-container__goals__goal__reward">
      <div>
        <img *ngIf="achievedGoals !== allGoals" src="{{ goalAssetsPath + 'un-check.svg' }}" />
        <img *ngIf="achievedGoals === allGoals" src="{{ goalAssetsPath + 'check.svg' }}" />
      </div>
      <div
        *ngIf="isMoneyBack; else badgeReward"
        class="goal-card-container__goals__goal__reward__cashback"
      >
        <img src="{{ goalAssetsPath + 'cashback.svg' }}" alt="" />
        <div class="goal-card-container__goals__goal__reward__cashback__description">
          <p class="heading2--extra-bold text-secondary">
            {{
              'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.REWARD.TITLE.CASHBACK'
                | translate : { currentCurrency, amount }
            }}
          </p>
          <p class="body2--medium text-content-medium">
            {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.REWARD.SUBTITLE.CASHBACK' | translate }}
          </p>
        </div>
      </div>
    </div>
    <ng-template #badgeReward>
      <div class="goal-card-container__goals__goal__reward__cashback">
        <img src="{{ goalAssetsPath + 'reward.svg' }}" alt="" />
        <div class="goal-card-container__goals__goal__reward__cashback__description">
          <p class="heading2--extra-bold text-secondary">
            {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.REWARD.TITLE.BADGE' | translate }}
          </p>
          <p class="body2--medium text-content-medium">
            {{ 'ONBOARDING.ONBOARDING_GOAL_PROGRESS_CARD.REWARD.SUBTITLE.BADGE' | translate }}
          </p>
        </div>
      </div>
    </ng-template>
  </div>
</div>

<div
  [ngClass]="{
    'has-shade': (currentActiveStepSelector$ | async) !== (isOnboardingV2Enabled ? 3 : 4)
  }"
  (click)="onBackdropClick()"
  class="backdrop"
></div>
