import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { getLocalizedValue } from '@features/drivers/data';
import { getOnboardingCashback } from '@features/onboarding/data';
import { TranslateModule } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetOnboardingFeatureUseCase } from 'src/app/core/usecases/get-onboarding-feature-usecase';
import { FEATURE_FLAGS } from 'src/app/presentation/shared/constants';
import { ONBOARDING_MAX_ORDER_PROGRESS } from 'src/app/presentation/shared/constants/onboarding';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { GetOnboardingOrdersTrackingUseCase } from '../../../../core/usecases/get-onboarding-orders-tracking-usecase';
import { TooltipDirective } from '../../../shared/directives/tooltip/tooltip.directive';
import { SharedProgressBarComponent } from '../../shared/shared-progress-bar/shared-progress-bar.component';
import { OnboardingStoryGuideStateManager } from '../../state-manager/onboarding-story-guide.state-manager';
import {
  SelectCurrentActiveUserStepIndex,
  SelectRewardTypeForUse,
  SelectUserOnboardingCompletionStatus,
} from '../../state-manager/selectors/selector-names';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const _cio: any;

@Component({
  selector: 'app-onboarding-goal-progress-card',
  templateUrl: './onboarding-goal-progress-card.component.html',
  styleUrls: ['./onboarding-goal-progress-card.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    TooltipDirective,
    SharedProgressBarComponent,
    NgFor,
    NgClass,
    AsyncPipe,
    TranslateModule,
  ],
})
export class OnboardingGoalProgressCardComponent implements OnInit {
  @ViewChild('wrapperElementTemplate', { static: false, read: ViewContainerRef })
  public wrapperElementTemplateRef: ViewContainerRef;

  @Output() backdropClicked$: EventEmitter<boolean>;

  public goalAssetsPath = '../../../../../assets/img/on-boarding/';

  public isMoneyBack = false;

  public isBadge = false;

  public progressPercentage = 0;

  public achievedGoals = 0;

  public allGoals = 0;

  public userRewardType$: Observable<string>;

  public amount = getOnboardingCashback();

  public tooltipComponentRef = 'OnboardingNextStepTooltipComponent';

  public currentActiveStepSelector$: Observable<number>;

  public hasCompletedOnboardingStory$: Observable<boolean>;

  public dataIsLoaded: boolean;

  public currentCurrency: string;

  public isOnboardingV2Enabled = false;

  constructor(
    private _onboardingStoryGuideStateManager: OnboardingStoryGuideStateManager,
    private _getOnboardingFeatureUseCase: GetOnboardingFeatureUseCase,
    private _getOnboardingOrdersTrackingUseCase: GetOnboardingOrdersTrackingUseCase,
    private _changeDetectorRef: ChangeDetectorRef,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
    private _renderer2: Renderer2,
    private _multitenancyService: MultitenancyService,
    private _getFeatureFlagUseCase: GetFeatureFlagUsecase,
  ) {
    this.backdropClicked$ = new EventEmitter<boolean>();
    this.userRewardType$ =
      this._onboardingStoryGuideStateManager.selectStatePiece(SelectRewardTypeForUse);
    this.currentActiveStepSelector$ = this._onboardingStoryGuideStateManager.selectStatePiece(
      SelectCurrentActiveUserStepIndex,
    );
    this.hasCompletedOnboardingStory$ = this._onboardingStoryGuideStateManager.selectStatePiece(
      SelectUserOnboardingCompletionStatus,
    );
    this.currentCurrency = getLocalizedValue(
      this._multitenancyService.getCurrentCountry().currency,
    );
    this.dataIsLoaded = false;
  }

  ngOnInit(): void {
    this.checkOnboardingV2();
    const onboardingFeature$ = this._getOnboardingFeatureUseCase.execute();
    const ordersTracking$ = this._getOnboardingOrdersTrackingUseCase.execute();
    combineLatest({
      onboardingFeature: onboardingFeature$,
      ordersTracking: ordersTracking$,
    }).subscribe({
      next: ({ onboardingFeature, ordersTracking }) => {
        this.allGoals = ONBOARDING_MAX_ORDER_PROGRESS;
        this.achievedGoals = ordersTracking.orders.length;
        this.progressPercentage = (this.achievedGoals / this.allGoals) * 100;
        this.isMoneyBack = onboardingFeature?.rewardType === 'cashback';
        this.isBadge = onboardingFeature?.rewardType === 'badge';
        this.dataIsLoaded = true;
        this._changeDetectorRef.detectChanges();
        this._calculateAppropriateHeight();
        const eventName = 'onboarding_order_tracking_progress_load';
        const payload = {
          reward_type: onboardingFeature?.rewardType,
          progress_score: `${this.achievedGoals} of ${this.allGoals}`,
        };
        this._logMixpanelEventUseCase.execute({ eventName, payload });
        if (typeof _cio !== 'undefined') {
          _cio.track(eventName, payload);
        }
      },
      error: () => {},
    });
  }

  checkOnboardingV2() {
    this._getFeatureFlagUseCase.execute(FEATURE_FLAGS.ONBOARDING_V2).subscribe({
      next: (flag) => {
        this.isOnboardingV2Enabled = flag;
      },
    });
  }

  /**
   * When the backdrop has been clicked, we emit the event
   */
  onBackdropClick(): void {
    this.backdropClicked$.emit(false);
  }

  /**
   * Calculate the appropriate height for the element
   */
  private _calculateAppropriateHeight(): void {
    const infoHeaderElement = document.getElementsByTagName('info-header')[0];
    const infoHeaderElementDims = infoHeaderElement?.getBoundingClientRect();
    const infoHeaderHeight = infoHeaderElementDims?.y < 0 ? 0 : infoHeaderElementDims.height;
    const appMainHeaderDims = document
      .getElementsByTagName('app-main-header')[0]
      ?.getBoundingClientRect();
    const appMainHeaderHeight = appMainHeaderDims?.y < 0 ? 0 : appMainHeaderDims.height;
    const appStickyHeader = document.getElementsByClassName('navigation-lower-section')[0];
    const appStickyHeaderDims = appStickyHeader?.getBoundingClientRect();
    const appStickyHeaderHeight = appStickyHeaderDims.y < 0 ? 0 : appStickyHeaderDims.height;

    /**
     * use the navigation lower section to find it's end on the UI, with
     * respect to y axis, then this we calculate the offset from the start
     * and use it
     */

    let calculatedOffsetFromTopHeight =
      infoHeaderHeight + appMainHeaderHeight + appStickyHeaderHeight;
    const headerElementPadding = parseInt(
      window.getComputedStyle(document.getElementsByClassName('info-header-wrapper')[0]).paddingTop,
      10,
    );
    if (infoHeaderHeight > 0 && appMainHeaderHeight > 0 && appStickyHeaderHeight > 0) {
      calculatedOffsetFromTopHeight -= headerElementPadding;
    } else {
      if (infoHeaderHeight === 0 && appMainHeaderHeight === 0) {
        calculatedOffsetFromTopHeight += headerElementPadding;
      }
    }
    if (this.wrapperElementTemplateRef) {
      this._renderer2.setStyle(
        this.wrapperElementTemplateRef?.element?.nativeElement,
        'height',
        `calc(100% - ${calculatedOffsetFromTopHeight}px)`,
      );
    }
  }

  @HostListener('window:scroll')
  onWindowScroll(): void {
    this._calculateAppropriateHeight();
  }
}
